<template>
  <div>
    <!-- profileimg -->

    <div v-if="blnIsUserLoggedIn">
      <div class="profileimg" @click="uploadAvatar">
        <div class="userimg">
          <img :src="getAvatarFileName()" />
        </div>
        <div class="editdiv">
          <a href="#"
            ><font-awesome-icon icon="fa-solid fa-camera" />
            {{ $t("UPLOAD") }}</a
          >
        </div>

        <div class="_name">
          {{ getUserName() }}
        </div>
      </div>
      <!-- profileimg -->

      <!-- menulink -->
      <div class="menulinks" v-if="$strBrandingId == 'ja'">
        <ul>
          <li>
            <router-link :to="{ name: 'Home' }">
              <span class="icon-homes">
                <font-awesome-icon icon="fa-solid fa-home" />
              </span>
              <div>{{ $t("BUY_NOW") }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Profile' }">
              <span class="user-icon">
                <font-awesome-icon icon="fa-solid fa-user" />
              </span>
              <div>{{ $t("PROFILE") }}</div></router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Wallet' }">
              <span class="wallet-card">
                <font-awesome-icon icon="fa-solid fa-wallet" />
              </span>
              <div>{{ $t("MY_WALLET") }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'MyBets' }">
              <span class="bet-icon">
                <font-awesome-icon icon="fa-solid fa-coins" />
              </span>
              <div>{{ $t("MY_BETS") }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Cart' }">
              <span class="bell-icon">
                <font-awesome-icon icon="fa-solid fa-cart-shopping" />
              </span>
              <div>My Cart</div>
            </router-link>
          </li>
          <li class="logout">
            <b-button
              @click="logout"
              class="logoutbtn _lrdevice"
              expanded
              type="is-primary"
              >{{ $t("LOGOUT") }}</b-button
            >
            <b-button
              @click="logout"
              class="logoutbtn _smdevice"
              expanded
              type="is-primary"
            >
              <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
              <div>{{ $t("LOGOUT") }}</div>
            </b-button>
          </li>
        </ul>
      </div>

      <div class="menulinks drcdiv" v-else-if="$strBrandingId == '888drc'">
        <ul>
          <li>
            <router-link :to="{ name: 'Aviator' }">
              <span class="icon-homes">
                <font-awesome-icon icon="fa-solid fa-plane" />
              </span>
              <div>{{ $t("AVIATOR") }}</div>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'Home' }">
              <span class="icon-homes">
                <font-awesome-icon icon="fa-solid fa-home" />
              </span>
              <div>{{ $t("LOTTO") }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Profile' }">
              <span class="user-icon">
                <font-awesome-icon icon="fa-solid fa-user" />
              </span>
              <div>{{ $t("PROFILE") }}</div></router-link
            >
          </li>

          <li>
            <router-link :to="{ name: 'Wallet' }">
              <span class="wallet-card">
                <font-awesome-icon icon="fa-solid fa-wallet" />
              </span>
              <div>{{ $t("WALLET") }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'MyBets' }">
              <span class="bet-icon">
                <font-awesome-icon icon="fa-solid fa-coins" />
              </span>
              <div>{{ $t("MY_BETS") }}</div>
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'Aviator' }" target="_blank">
              <span class="bet-icon">
                <font-awesome-icon icon="fa-solid fa-smile" />
              </span>
              <div>{{ $t("AVIATOR") }}</div>
            </router-link>
          </li> -->

          <li class="logout">
            <b-button
              @click="logout"
              class="logoutbtn _lrdevice"
              expanded
              type="is-primary"
              >{{ $t("LOGOUT") }}</b-button
            >
            <b-button
              @click="logout"
              class="logoutbtn _smdevice"
              expanded
              type="is-primary"
            >
              <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
              <div>{{ $t("LOGOUT") }}</div>
            </b-button>
          </li>
        </ul>
      </div>

      <div class="menulinks drcdiv" v-else-if="$strBrandingId == 'hs'">
        <ul>
          <li>
            <router-link :to="{ name: 'Home' }">
              <span class="icon-homes">
                <font-awesome-icon icon="fa-solid fa-home" />
              </span>
              <div>{{ $t("LOTTO") }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Profile' }">
              <span class="user-icon">
                <font-awesome-icon icon="fa-solid fa-user" />
              </span>
              <div>{{ $t("PROFILE") }}</div></router-link
            >
          </li>

          <li>
            <router-link :to="{ name: 'Wallet' }">
              <span class="wallet-card">
                <font-awesome-icon icon="fa-solid fa-wallet" />
              </span>
              <div>{{ $t("WALLET") }}</div>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'MyBets' }">
              <span class="bet-icon">
                <font-awesome-icon icon="fa-solid fa-coins" />
              </span>
              <div>{{ $t("MY_BETS") }}</div>
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'Aviator' }" target="_blank">
              <span class="bet-icon">
                <font-awesome-icon icon="fa-solid fa-smile" />
              </span>
              <div>{{ $t("AVIATOR") }}</div>
            </router-link>
          </li> -->

          <li class="logout">
            <b-button
              @click="logout"
              class="logoutbtn _lrdevice"
              expanded
              type="is-primary"
              >{{ $t("LOGOUT") }}</b-button
            >
            <b-button
              @click="logout"
              class="logoutbtn _smdevice"
              expanded
              type="is-primary"
            >
              <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
              <div>{{ $t("LOGOUT") }}</div>
            </b-button>
          </li>
        </ul>
      </div>

      <b-modal v-model="blnShowAvatarUploader" class="uploaderdiv">
        <ChangeAvatar @close="handleClose"></ChangeAvatar>
      </b-modal>
      <!-- menulink -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChangeAvatar from "./ChangeAvatar.vue";

export default {
  name: "Sidebar",
  components: { ChangeAvatar },
  computed: {
    ...mapGetters(["objUser", "blnIsUserLoggedIn"]),
  },
  data() {
    return {
      objModel: {
        avatar_filename: "",
      },
      blnShowAvatarUploader: false,
    };
  },
  methods: {
    getAvatarFileName() {
      let strAvatarFileName = this.objUser.avatar_filename;
      if (strAvatarFileName) {
        let strUrl =
          "https://s3.eu-west-1.amazonaws.com/imprexis-banners-bucket/";
        strUrl += strAvatarFileName;
        return strUrl;
      }
      var images = require.context("../assets/", false);
      return images("./" + "jackpotaruba.png");
    },
    handleClose() {
      this.blnShowAvatarUploader = false;
      // this.load();
    },
    uploadAvatar() {
      this.blnShowAvatarUploader = true;
    },
    getUserName() {
      try {
        let { first_name, last_name } = this.objUser;
        if (first_name && last_name) {
          return first_name + " " + last_name;
        }
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    logout() {
      this.$store.commit("logout");
      this.$utils.emitWCEvent(this, "loggedOut");
      this.setAxiosToken("");
      this.$router.replace({ name: "Logout" });
    },
  },
};
</script>
