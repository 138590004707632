<template>
  <div ref="element" :closable="false">
    <!-- dir="rtl" -->
    <!-- {{ objUser }} -->
    <div :dir="getDir()" :class="getClass()">
      <div class="maincolumns">
        <!-- {{ objUser }}{{ strToken }} -->
        <!-- {{ $store.state.blnLoading }} -->
        <div class="contentdiv">
          <router-view v-if="!$store.state.blnLoading"></router-view>

          <b-loading
            :is-full-page="false"
            v-model="$store.state.blnLoading"
            :can-cancel="false"
          ></b-loading>
        </div>

        <div class="sidebar">
          <Sidebar />
        </div>
      </div>
    </div>

    <div class="version text-center" style="color: #fff">
      v{{ $utils.strVersionNo }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Sidebar from "../components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
  computed: {
    ...mapGetters(["objUser", "strToken"]),
  },
  methods: {
    getClass() {
      let strClass = "mainbody";
      if (this.objUser && this.objUser.id) {
        strClass += " logged_in ";
      } else {
        strClass += " notloggedin ";
      }
      if (this.$strBrandingId == "888drc") {
        strClass += " drc ";
      }
      if(this.$strBrandingId == "hs") {
        strClass += " hs ";
      }
      return strClass;
    },
    open() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el,
      });
      setTimeout(() => loadingComponent.close(), 3 * 1000);
    },
    getDir() {
      let strLocale = this.$i18n.locale;
      if (strLocale == "fa") {
        return "rtl";
      } else {
        return "";
      }
    },
  },
};
</script>
<style></style>
